import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { CheckmarkFilled16 } from '@carbon/icons-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon add-ons</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Contributing to add-ons</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Ownership and maintenance</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "carbon-add-ons"
    }}>{`Carbon add-ons`}</h2>
    <p>{`Carbon add-ons contain components, tools, and guidance that extend Carbon for a specific product or experience. Add-ons enable teams to create their own custom patterns and components that follow Carbon’s visual style and guidelines.`}</p>
    <p>{`If your team is using Carbon and needs components specific to your product or industry, you should create a Carbon add-on.`}</p>
    <p>{`If you feel that your components and/or patterns could be used in other products, we encourage you to `}<a parentName="p" {...{
        "href": "/how-to-contribute/overview"
      }}>{`contribute`}</a>{` your work back to Carbon.`}</p>
    <h3 {...{
      "id": "private-vs-public"
    }}>{`Private vs public`}</h3>
    <p>{`The Carbon Design System is an open-source project and we encourage teams using Carbon Design System to stay open-source as well. If your product has privacy constraints, there are options for creating an add-on repo under our GitHub Enterprise account.`}</p>
    <h2 {...{
      "id": "contributing-to-add-ons"
    }}>{`Contributing to add-ons`}</h2>
    <p>{`Add-ons are generally easier to contribute to because they are not fully managed by the Carbon team. For your add-on to be accepted, your components must meet WCAG AA standards and include interaction states (hover, active, focus, and disabled).`}</p>
    {
      /*
      | Category      | Metric                                              | Core library                                         | Add-ons                                              |
      | ------------- | --------------------------------------------------- | ---------------------------------------------------- | ---------------------------------------------------- |
      | Accessibility | WCAG AA                                             | ✅ | ✅ |
      |               | Keyboard tabbing                                    | ✅ | ✅ |
      |               | Carbon custom focus states                          | ✅ |                                                      |
      | Visual + UX   | Carbon themed                                       | ✅ |                                                      |
      |               | Universal pattern <br />(fits for 2-3 use cases)    | ✅ |                                                      |
      |               | Interaction states (hover, active, focus, disabled) | ✅ | ✅ |
      */
    }
    <h2 {...{
      "id": "ownership-and-maintenance"
    }}>{`Ownership and maintenance`}</h2>
    <p>{`If you build an add-on repo, it’s your responsibility to maintain it. This involves carrying over changes from the core Carbon repo, as well as making sure it is using the latest major version of Carbon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      